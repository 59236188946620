import React from 'react';
import { Card } from 'react-bootstrap';

export default function JackieMusicPage(props) {
    return(
        <Card>
        {/* <embed src="https://linktr.ee/jackiekopco" style="width:500px; height: 300px;">  */}
        <iframe width="100%" height="300" scrolling="yes" frameborder="yes" src="https://linktr.ee/jackiekopco" title="Jackie's Music Linktree"></iframe>

        
        </Card> 
    )
}