import React from 'react';
import NavBar from './NavBarComponent';


function Header() {
    return ( 
        <NavBar />
     );
}

export default Header;
